export function formatDuration(seconds: number): string {
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);

	if (days > 0) {
		return `${days} day${days > 1 ? "s" : ""}`;
	}
	if (hours > 0) {
		return `${hours} hour${hours > 1 ? "s" : ""}`;
	}
	return `${minutes} minute${minutes > 1 ? "s" : ""}`;
}
