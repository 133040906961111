import type React from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { FilePlusIcon, LightningBoltIcon } from "@radix-ui/react-icons";
import { formatDuration } from "@/utils/formatDuration";
import type { DialogProps } from "@/contexts/dialogContext";
import { FormItem } from "@/components/formItem";
import { Gauge } from "lucide-react";
import { LayoutDialog } from "./layoutDialog";
import { fromSun } from "@/utils/convertCurrency";

export const CreateOrderDialog: React.FC<DialogProps<"createOrder">> = ({
	isOpen,
	onClose,
	onConfirm,
	payload,
}) => {
	const {
		resource,
		duration,
		price,
		targetAddress,
		partialFill,
		resourceAmount,
		totalPrice,
	} = payload;

	return (
		<LayoutDialog
			isOpen={isOpen}
			onClose={onClose}
			icon={<FilePlusIcon className="h-8 w-8 text-primary-foreground" />}
			title="Create the order"
			onConfirm={onConfirm}
			confirmText="Confirm Order"
		>
			<p className="mb-4 text-center text-muted-foreground">
				Please confirm to proceed with the order
			</p>
			<div className="grid gap-4 py-4">
				<FormItem label="Amount">
					<p>{resourceAmount.toLocaleString()}</p>
				</FormItem>
				<FormItem label="Resource">
					<p>{resource === "ENERGY" ? "Energy" : "Bandwidth"}</p>
				</FormItem>
				<FormItem label="Duration">
					<p>{formatDuration(duration)}</p>
				</FormItem>
				<FormItem label="Price">
					<p>{price}</p>
				</FormItem>
				<FormItem label="Target TRX Address">
					<p className="text-sm">{targetAddress}</p>
				</FormItem>
				<FormItem label="Options">
					<div className="flex items-center space-x-2">
						<Checkbox id="allowPartialFill" checked={partialFill} />
						<label
							htmlFor="allowPartialFill"
							className="font-medium text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
						>
							Allow Partial Fill
						</label>
					</div>
				</FormItem>
				<div className="border-t pt-4">
					<div className="flex justify-between">
						<span>{resource === "ENERGY" ? "Energy" : "Bandwidth"}</span>
						<span className="flex items-center gap-1 text-primary">
							{resourceAmount.toLocaleString()}{" "}
							{resource === "ENERGY" ? (
								<LightningBoltIcon className="h-4 w-4" />
							) : (
								<Gauge className="h-4 w-4" />
							)}
						</span>
					</div>
					<div className="flex justify-between">
						<span className="flex items-center gap-1">Total</span>
						<span>{fromSun(totalPrice)} TRX</span>
					</div>
				</div>
			</div>
		</LayoutDialog>
	);
};
