import { Mail, MessageCircle } from "lucide-react";
import { Link } from "@tanstack/react-router";
import { useDialogContext } from "@/contexts/dialogContext";

export function Footer() {
	const { openDialog } = useDialogContext();

	return (
		<footer className="border-t bg-card/80 py-6 text-card-foreground text-md shadow-sm backdrop-blur-sm">
			<div className="mx-auto w-full max-w-[1150px] px-2">
				<div className="grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-4">
					<div className="space-y-3">
						<h3 className="font-semibold text-lg">About</h3>
						<ul className="space-y-2">
							<li>
								<p
									onClick={() => openDialog("faq", {})}
									className="cursor-pointer text-muted-foreground transition-colors hover:text-primary"
								>
									FAQ
								</p>
							</li>
							<li>
								<p
									className="cursor-pointer text-muted-foreground transition-colors hover:text-primary"
									onClick={() => openDialog("terms", {})}
								>
									Terms & Conditions
								</p>
							</li>
						</ul>
					</div>
					<div className="space-y-3">
						<h3 className="font-semibold text-lg">Support</h3>
						<ul className="space-y-2">
							<li>
								<a
									href="mailto:support@tronspace.vip"
									className="inline-flex items-center gap-2 text-muted-foreground transition-colors hover:text-primary"
								>
									<Mail className="h-4 w-4" />
									Email Support
								</a>
							</li>
							<li>
								<a
									href="https://t.me/tronspace_vip"
									target="_blank"
									rel="noopener noreferrer"
									className="inline-flex items-center gap-2 text-muted-foreground transition-colors hover:text-primary"
								>
									<MessageCircle className="h-4 w-4" />
									Telegram Support
								</a>
							</li>
						</ul>
					</div>
					<div className="space-y-3">
						<h3 className="font-semibold text-lg">Resources</h3>
						<ul className="space-y-2">
							<li>
								<Link
									href="/documentation"
									className="text-muted-foreground transition-colors hover:text-primary"
								>
									Documentation
								</Link>
							</li>
							<li>
								<Link
									href="/api"
									className="text-muted-foreground transition-colors hover:text-primary"
								>
									API
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="mt-8 border-t pt-8 text-muted-foreground text-sm">
					<p>© {new Date().getFullYear()} Tronspace VIP. All rights reserved.</p>
				</div>
			</div>
		</footer>
	);
}
