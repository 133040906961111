// apps/web/src/components/dialog/passwordDialog.tsx
import type React from "react";
import { useState } from "react";
import { LockClosedIcon } from "@radix-ui/react-icons";
import type { DialogProps } from "@/contexts/dialogContext";
import { LayoutDialog } from "./layoutDialog";
import { FormItem } from "@/components/formItem";
import { Input } from "@/components/ui/input";

export const PasswordDialog: React.FC<DialogProps<"password">> = ({
	isOpen,
	onClose,
	onConfirm,
	payload,
}) => {
	const [password, setPassword] = useState("");

	const handleConfirm = () => {
		if (onConfirm) {
			onConfirm(password);
		}
	};

	return (
		<LayoutDialog
			isOpen={isOpen}
			onClose={onClose}
			icon={<LockClosedIcon className="h-8 w-8 text-primary-foreground" />}
			title={payload.title}
			onConfirm={handleConfirm}
			confirmText={payload.confirmButtonText ?? "Confirm"}
		>
			<p className="mb-4 text-center text-muted-foreground">
				{payload.description}
			</p>
			<div className="grid gap-4 py-4">
				<FormItem label="Password">
					<div className="relative">
						<Input
							type={"password"}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="Enter admin password"
						/>
					</div>
				</FormItem>
			</div>
		</LayoutDialog>
	);
};
