import { createEnv as createT3Env } from "@t3-oss/env-core";
import { z } from "zod";

const createEnv = ({ runtimeEnv }: { runtimeEnv: NodeJS.ProcessEnv }) => {
	return createT3Env({
		server: {},
		clientPrefix: "VITE_",
		client: {
			VITE_API_URL: z.string().url(),
			VITE_TEST_ENV: z.string().optional(),
			VITE_TRON_API_URL: z.string().url(),
		},
		runtimeEnv: runtimeEnv,
		emptyStringAsUndefined: true,
	});
};

export const env = createEnv({ runtimeEnv: import.meta.env });
