import { Footer } from "./footer";
import { Header } from "./header";

interface Props {
	children: React.ReactNode;
}
export function Layout({ children }: Props) {
	return (
		<div>
			<div className="w-full pt-[70px] md:min-h-screen">
				<Header />
				<div className="mx-auto w-full max-w-[1150px] gap-5 px-2 py-4">
					{children}
				</div>
			</div>
			<Footer />
		</div>
	);
}
