import type React from "react";
import { CheckIcon, Cross1Icon } from "@radix-ui/react-icons";
import type { DialogProps } from "@/contexts/dialogContext";
import { LayoutDialog } from "./layoutDialog";

export const ResultDialog: React.FC<DialogProps<"result">> = ({
	isOpen,
	onClose,
	payload,
}) => {
	const { success, message, details } = payload;

	return (
		<LayoutDialog
			isOpen={isOpen}
			onClose={onClose}
			icon={
				success ? (
					<CheckIcon className="h-10 w-10" />
				) : (
					<Cross1Icon className="h-10 w-10" />
				)
			}
			removeCancelButton
			onConfirm={onClose}
			confirmText="Close"
			title={success ? "Success" : "Error"}
		>
			<div className="text-center">
				<p className="mb-2 font-medium text-lg">{message}</p>
				{details && <p className="text-muted-foreground text-sm">{details}</p>}
			</div>
		</LayoutDialog>
	);
};
