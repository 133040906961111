export const fromHex = (hex: string): string => {
	const matches = hex.match(/.{1,2}/g);
	if (!matches) {
		return "";
	}
	return matches.reduce(
		(acc, char) => acc + String.fromCharCode(Number.parseInt(char, 16)),
		"",
	);
};
