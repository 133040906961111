import { AdminMarketDataForm } from "@/components/adminMarketDataForm";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/adminMarketData")({
	component: AdminMarketDataPage,
});

function AdminMarketDataPage() {
	return (
		<div>
			<h1 className="mb-4 font-bold text-2xl text-primary">
				Admin Market Data Management
			</h1>
			<AdminMarketDataForm />
		</div>
	);
}
export default AdminMarketDataPage;
