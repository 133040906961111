import { Outlet, createRootRoute } from "@tanstack/react-router";
import { WalletProvider } from "@tronweb3/tronwallet-adapter-react-hooks";
import { Layout } from "../layout";
import { ThemeProvider } from "next-themes";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/lib/queryClient";
import { Toaster } from "@/components/ui/sonner";
import { DialogProvider } from "@/contexts/dialogContext";
import { TooltipProvider } from "@/components/ui/tooltip";
import { NuqsAdapter } from "nuqs/adapters/react";

export const Route = createRootRoute({
	component: RootComponent,
});

function RootComponent() {
	return (
		<NuqsAdapter>
			<QueryClientProvider client={queryClient}>
				<WalletProvider disableAutoConnectOnLoad={true} autoConnect={false}>
					<ThemeProvider defaultTheme="dark" attribute="class">
						<TooltipProvider delayDuration={200}>
							<DialogProvider>
								<Layout>
									<Outlet />
								</Layout>
								<Toaster richColors />
							</DialogProvider>
						</TooltipProvider>
					</ThemeProvider>
				</WalletProvider>
			</QueryClientProvider>
		</NuqsAdapter>
	);
}
