import type React from "react";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";

interface FAQDialogProps {
	isOpen: boolean;
	onClose: () => void;
}

export const FAQDialog: React.FC<FAQDialogProps> = ({ isOpen, onClose }) => {
	const faqItems = [
		{
			question: "What is Tron Energy?",
			answer:
				"TRON Energy on the TRX blockchain network is a resource enabling transactions, smart contract interactions, and various TRON ecosystem activities.",
		},
		{
			question: "How do I get TRON Energy?",
			answer:
				"Acquire TRON Energy by freezing TRX (staking), ensuring smooth transactions while minimizing service fees.",
		},
		{
			question: "How to Rent Energy?",
			answer:
				"Utilize Tronspace VIP wavefield energy trading for self-serve rentals with real-time payments, offering flexibility to suit your needs.",
		},
		{
			question: "What should I include in my personal statement?",
			answer:
				"Energy typically arrives within minutes after payment. In rare cases, delays of 5 to 10 minutes may occur.",
		},
		{
			question: "How Long for Energy Regeneration?",
			answer:
				"Energy regenerates freely based on staked TRX quantity. Full restoration occurs within 24 hours post-use.",
		},
		{
			question: "Advantages of API for Energy Purchases?",
			answer:
				"Faster, fully automated, and highly secure energy purchases through the API provide efficiency and convenience.",
		},
	];

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Frequently Asked Questions</DialogTitle>
				</DialogHeader>
				<div className="prose prose-gray max-h-96 max-w-none overflow-y-auto">
					<Accordion type="single" collapsible className="w-full">
						{faqItems.map((item, index) => (
							<AccordionItem key={item.question} value={`item-${index}`}>
								<AccordionTrigger>{item.question}</AccordionTrigger>
								<AccordionContent>{item.answer}</AccordionContent>
							</AccordionItem>
						))}
					</Accordion>
				</div>
				<DialogFooter>
					<Button onClick={onClose}>OK</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
